<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-button
        v-b-toggle.collapse-1
        class="btn btn-gradient-primary mb-1"
      >
        {{ $t('key-10') }}
      </b-button>

      <b-collapse id="collapse-1">
        <div class="card">
          <div class="header-cardx p-2">
            <h3 class="mb-0">
              เพิ่มข้อมูลใหม่
            </h3>
          </div>
          <div class="p-2">
            <b-row>
              <b-col md="7">
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      label-for="h-parcel-code"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        รหัสพัสดุ <span class="text-danger">*</span>
                      </template>

                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <i class="fal fa-barcode-read" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="h-parcel-code"
                          v-model="parcelCode"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      label-for="h-order-list"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        รายการสั่งซื้อ :
                      </template>

                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <i class="far fa-cart-arrow-down" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="h-order-list"
                          v-model="orderList"
                        />
                      </b-input-group>
                    </b-form-group>

                    <b-form-group
                      label-for="h-number"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        ลำดับ :
                      </template>
                      <div class="d-flex align-items-center">
                        <b-form-input
                          id="h-number"
                          v-model="number"
                          type="number"
                          class="w-50"
                        />

                        <button
                          type="button"
                          class="btn btn-primary ml-50"
                          @click="handleSearch()"
                        >
                          ค้นหาลำดับ
                        </button>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label-for="h-amount"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        จำนวน :
                      </template>
                      <b-form-input
                        id="h-amount"
                        v-model="amount"
                        type="number"
                        class="w-50"
                        step="1"
                      />
                    </b-form-group>

                    <b-form-group
                      label-for="h-product-type"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        ประเภทสินค้า <span class="text-danger">*</span>
                      </template>
                      <v-select
                        id="h-product-type"
                        v-model="productType"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text"
                        :options="productlist"
                        class="w-50 mr-1"
                      />
                    </b-form-group>
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label-for="h-wooden-crate"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            ตีลังไม้ :
                          </template>
                          <b-form-checkbox
                            id="h-wooden-crate"
                            v-model="woodenCrate"
                            name="check-buttons"
                            :value="1"
                            :unchecked-value="0"
                            switch
                            inline
                            class="mt-50"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-for="h-check-product"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            เช็คสินค้า :
                          </template>
                          <b-form-checkbox
                            id="h-check-product"
                            v-model="checkProduct"
                            name="check-buttons"
                            :value="1"
                            :unchecked-value="0"
                            switch
                            inline
                            class="mt-50"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="12">
                    <b-row>
                      <b-col md="6">
                        <b-form-group
                          label-for="h-product-weight"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            น้ำหนัก
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-product-weight"
                              v-model="productWeight"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              กก.
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-product-height"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            สูง
                          </template>

                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-product-height"
                              v-model="productHeight"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ซม.
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-product-width"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            กว้าง
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-product-width"
                              v-model="productWidth"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ซม.
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-product-long"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            ยาว
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-product-long"
                              v-model="productLong"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ซม.
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col md="12">
                    <hr>
                    <b-row class="pt-1">
                      <b-col md="6">
                        <b-form-group
                          label-for="h-check-product-price"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            เช็คสินค้า
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-check-product-price"
                              v-model="checkProductPrice"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ฿
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-trans-price"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            ขนส่ง
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-trans-price"
                              v-model="transPrice"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ฿
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>
                      <b-col md="6">
                        <b-form-group
                          label-for="h-new-wrap"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            ห่อใหม่
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-new-wrap"
                              v-model="newWrap"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ฿
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-wooden-price"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            ราคาตีลัง
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-wooden-price"
                              v-model="woodenPrice"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ฿
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6">
                        <b-form-group
                          label-for="h-other"
                          label-cols-md="4"
                        >
                          <template v-slot:label>
                            อื่นๆ
                          </template>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="h-other"
                              v-model="other"
                              type="number"
                              placeholder="0"
                            />
                            <b-input-group-append is-text>
                              ฿
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </b-col>

                      <b-col md="6" />
                    </b-row>
                  </b-col>

                  <b-col md="12">
                    <b-form-group
                      label-for="h-file1"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        ไฟล์แนบขนส่ง :
                      </template>
                      <b-form-file
                        id="h-file1"
                        v-model="file"
                        placeholder="Choose a file or drop it here..."
                        drop-placeholder="Drop file here..."
                      />
                    </b-form-group>

                    <b-form-group
                      label-for="h-files-name"
                      label-cols-md="2"
                    >
                      <template v-slot:label>
                        รูปสินค้า <span class="text-danger">*</span>
                      </template>
                      <b-form-file
                        v-model="selectedFiles"
                        :state="Boolean(selectedFiles.length)"
                        multiple
                        @change="handleFileSelect"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col md="5">
                <b-form-group
                  label-for="h-customerCode"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    <span class="text-danger">*</span> รหัสลูกค้า
                    <span class="text-primary">
                      <i
                        v-b-tooltip.hover.top="'Copy'"
                        v-clipboard:copy="message"
                        :class="iconClass"
                        @click="handleCopy"
                      />
                    </span>
                  </template>
                  <v-select
                    id="h-customerCode"
                    v-model="customerCode"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="text"
                    :options="customerCodeList"
                    class="w-100 mr-1"
                  />
                </b-form-group>

                <b-form-group
                  label-for="h-remark-new"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    หมายเหตุ :
                  </template>
                  <b-form-textarea
                    id="h-remark-new"
                    v-model="remark"
                    type="text"
                  />
                </b-form-group>

                <b-form-group
                  label-for="h-file1"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    ราคาที่บันทึก :
                  </template>
                  <div class="h5 mb-0 mt-50">
                    0.00 ฿
                  </div>
                </b-form-group>

                <b-form-group
                  label-for="h-select-price"
                  label-cols-md="3"
                >
                  <template v-slot:label>
                    เลือกราคา :
                  </template>

                  <div>
                    <b-form-radio
                      v-model="selectPrice"
                      name="some-selectPrice"
                      :value="1"
                      class="custom-control-primary mb-50 mt-50"
                    >
                      ราคาตามกิโล 0.00 ฿
                    </b-form-radio>

                    <b-form-radio
                      v-model="selectPrice"
                      name="some-selectPrice"
                      :value="2"
                      class="custom-control-primary"
                    >
                      ราคาตามคิว 0.00 ฿
                    </b-form-radio>
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-collapse>

      <b-row class="match-height">
        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                ล๊อตสินค้า LOT-10
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label-for="h-product-name"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          หมายเลขรายการ :
                        </template>
                        <b-form-input
                          id="h-product-name"
                          v-model="fullname"
                          disabled
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-name"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          ชื่อ :
                        </template>
                        <b-form-input
                          id="h-name"
                          v-model="name"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-remark"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          หมายเหตุ :
                        </template>
                        <b-form-textarea
                          id="h-remark"
                          v-model="remark"
                          type="text"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-tran-type"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          ประเภทการขนส่ง :
                        </template>

                        <div class="demo-inline-spacing mt-n1">
                          <b-form-radio
                            v-model="transportType"
                            name="some-radios"
                            value="car"
                          >
                            รถ
                          </b-form-radio>
                          <b-form-radio
                            v-model="transportType"
                            name="some-radios"
                            value="ship"
                          >
                            เรือ
                          </b-form-radio>
                        </div>
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-discount"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          ส่วนลด(%) :
                        </template>
                        <b-form-input
                          id="h-discount"
                          v-model="discount"
                          type="number"
                          class="w-25"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-amount-export"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          จำนวนที่ส่งออก :
                        </template>
                        <b-form-input
                          id="h-amount-export"
                          v-model="amountExport"
                          type="number"
                          class="w-25"
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-file1"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          ไฟล์แนบ :
                        </template>
                        <b-form-file
                          id="h-file1"
                          v-model="file"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                        />
                      </b-form-group>
                    </b-col>

                    <b-col md="12">
                      <b-form-group
                        label-for="h-files-picture"
                        label-cols-md="3"
                      >
                        <template v-slot:label>
                          เพิ่มรูปภาพ
                        </template>
                        <b-form-file
                          id="h-files-picture"
                          v-model="selectedFiles"
                          :state="Boolean(selectedFiles.length)"
                          multiple
                          @change="handleFileSelect"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <button
                    class="btn btn-gradient-primary mb-1"
                    @click="Submit()"
                  >
                    บันทึกข้อมูล
                  </button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>

      <div class="card">
        <div class="header-cardx p-2">
          <div class="d-flex justify-content-between align-items-center">
            <h3 class="mb-0">
              รายการรหัสพัสดุ
            </h3>
          </div>
        </div>

        <div class="card">
          <div class="p-2 d-flex align-items-center justify-content-start">
            <v-select
              id="h-status"
              v-model="status"
              label="text"
              :options="statuslist"
              :reduce="(text) => text.value"
              class="w-25 mr-50"
            />

            <b-form-datepicker
              id="example-received"
              v-model="receivedDate"
              locale="th"
              class="w-25 mr-50"
            />

            <b-button
              variant="gradient-success"
              class="mr-50"
            >
              <i class="fal fa-download" /> บันทึก
            </b-button>

            <b-button
              variant="gradient-danger"
              class="mr-50"
              disabled
            >
              <i class="fal fa-redo" /> รีเซ็ต
            </b-button>

            <b-button
              variant="gradient-primary"
              class="mr-50"
            >
              <i class="fal fa-print" /> พิมพ์
            </b-button>

            <b-button
              variant="gradient-primary"
              class="mr-50"
            >
              <i class="fal fa-file-invoice" /> ใบรับสินค้า
            </b-button>
          </div>

          <div class="p-2">
            <table class="table table-responsive">
              <thead>
                <tr>
                  <th scope="col">
                    <template>
                      <div>
                        <input
                          v-model="isChecked"
                          type="checkbox"
                        >
                      </div>
                    </template>
                  </th>
                  <th scope="col">
                    จัดการ
                  </th>
                  <th scope="col">
                    ลำดับ
                  </th>
                  <th scope="col">
                    รหัสพัสดุ
                  </th>
                  <th scope="col">
                    เข้าโกดัง
                  </th>
                  <th scope="col">
                    ออกโกดัง
                  </th>
                  <th scope="col">
                    ถึงไทย
                  </th>
                  <th scope="col">
                    รหัสลูกค้า
                  </th>
                  <th scope="col">
                    จำนวน
                  </th>
                  <th scope="col">
                    น้ำหนัก
                  </th>
                  <th scope="col">
                    กว้าง
                  </th>
                  <th scope="col">
                    สูง
                  </th>
                  <th scope="col">
                    ยาว
                  </th>
                  <th scope="col">
                    คิว.
                  </th>
                  <th scope="col">
                    ประเภท
                  </th>
                  <th scope="col">
                    จัดวาง
                  </th>
                  <th scope="col">
                    เช็คสินค้า
                  </th>
                  <th scope="col">
                    ห่อใหม่
                  </th>
                  <th scope="col">
                    ขนส่ง
                  </th>
                  <th scope="col">
                    อื่นๆ
                  </th>
                  <th scope="col">
                    ตีลัง
                  </th>
                  <th scope="col">
                    ราคากิโล
                  </th>
                  <th scope="col">
                    ราคาคิว
                  </th>
                </tr>
              </thead>
              <thead class="text-right">
                <tr>
                  <th colspan="8">
                    รวม
                  </th>
                  <th scope="col">
                    3
                  </th>
                  <th scope="col">
                    00
                  </th>
                  <th scope="col">
                    00
                  </th>
                  <th scope="col">
                    000
                  </th>
                  <th scope="col">
                    000
                  </th>
                  <th scope="col">
                    00
                  </th>
                  <th scope="col">
                    000
                  </th>
                  <th scope="col">
                    0000
                  </th>
                  <th scope="col">
                    0
                  </th>
                  <th scope="col">
                    000
                  </th>
                  <th scope="col">
                    00000
                  </th>
                  <th scope="col">
                    00000
                  </th>
                  <th scope="col">
                    000000
                  </th>
                  <th scope="col">
                    000000
                  </th>
                  <th scope="col">
                    000000
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="col">
                    <template>
                      <div>
                        <input
                          v-model="isChecked"
                          type="checkbox"
                        >
                      </div>
                    </template>
                  </th>
                  <th scope="col">
                    1
                  </th>
                  <th scope="col">
                    .
                  </th>
                  <th scope="col">
                    773167606350835
                  </th>
                  <th scope="col">
                    27/06/2022
                  </th>
                  <th scope="col">
                    27/06/2022
                  </th>
                  <th scope="col">
                    15/07/2022
                  </th>
                  <th scope="col">
                    nt909
                  </th>
                  <th scope="col">
                    1
                  </th>
                  <th scope="col">
                    26.5
                  </th>
                  <th scope="col">
                    51.0
                  </th>
                  <th scope="col">
                    38.0
                  </th>
                  <th scope="col">
                    39.0
                  </th>
                  <th scope="col">
                    0.0755
                  </th>
                  <th scope="col">
                    B
                  </th>
                  <th scope="col">
                    000
                  </th>
                  <th scope="col">
                    -
                  </th>
                  <th scope="col">
                    -
                  </th>
                  <th scope="col">
                    -
                  </th>
                  <th scope="col">
                    -
                  </th>
                  <th scope="col">
                    -
                  </th>
                  <th scope="col">
                    530.00
                  </th>
                  <th scope="col">
                    -
                  </th>
                </tr>
              </tbody>
              <tfoot class="text-right">
                <tr>
                  <th colspan="8">
                    รวม
                  </th>
                  <th scope="col">
                    3
                  </th>
                  <th scope="col">
                    00
                  </th>
                  <th scope="col">
                    00
                  </th>
                  <th scope="col">
                    000
                  </th>
                  <th scope="col">
                    000
                  </th>
                  <th scope="col">
                    00
                  </th>
                  <th scope="col">
                    000
                  </th>
                  <th scope="col">
                    0000
                  </th>
                  <th scope="col">
                    0
                  </th>
                  <th scope="col">
                    000
                  </th>
                  <th scope="col">
                    00000
                  </th>
                  <th scope="col">
                    00000
                  </th>
                  <th scope="col">
                    000000
                  </th>
                  <th scope="col">
                    000000
                  </th>
                  <th scope="col">
                    000000
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormFile,
  BFormDatepicker,
  BCollapse,
  VBToggle,
  BFormCheckbox,
  BFormRadio,
  VBTooltip,
  BInputGroupPrepend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormFile,
    BFormDatepicker,
    BCollapse,
    BButton,
    BFormCheckbox,
    BFormRadio,
    BInputGroupPrepend,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isChecked: false,
      message: 'Copy',
      customerCodeList: [
        { text: 'A', value: 1 },
        { text: 'B', value: 2 },
        { text: 'C', value: 3 },
        { text: 'D', value: 4 },
        { text: 'D1', value: 5 },
        { text: 'S', value: 6 },
      ],
      customerCode: null,
      selectPrice: 1,
      discount: null,
      amountExport: null,
      items: [],
      // ID: this.$route.params.id,
      transportType: this.$route.params.transportType,
      remark: null,
      name: null,
      fullname: 'LOT-10',
      receivedDate: null,
      woodenCrate: 0,
      checkProduct: 0,
      productWeight: null,
      productHeight: null,
      productWidth: null,
      productLong: null,
      checkProductPrice: null,

      isActive: true,
      required,
      file: null,
      iconClass: 'fal fa-copy text-primary cursor-pointer',

      selectedFiles: [],
      uploadedImages: [],
      fieldsRelevant: [
        { key: 'ship', label: 'วันที่ทำรายการ', thStyle: { width: '20%' } },
        { key: 'kg', label: 'รายละเอียด', thStyle: { width: '60%' } },
        { key: 'amount', label: 'จำนวน', thStyle: { width: '20%' } },
      ],

      productlist: [
        { text: 'A', value: 1 },
        { text: 'B', value: 2 },
        { text: 'C', value: 3 },
        { text: 'D', value: 4 },
        { text: 'D1', value: 5 },
        { text: 'S', value: 6 },
      ],

      status: 'เข้าโกดัง',
      statuslist: [
        { text: 'เข้าโกดัง' },
        { text: 'ออกโกดัง' },
        { text: 'ถึงไทย' },
      ],
    }
  },

  mounted() {
    this.receivedDate = new Date()
    this.Getdata()
  },
  methods: {
    handleCopy() {
      this.iconClass = 'fal fa-clipboard-check text-success'

      setTimeout(() => {
        this.iconClass = 'fal fa-copy'
      }, 3500)
    },
    // async Getdata() {
    //   try {
    //     // const obj = {
    //     //   _id: this.ID,
    //     // }
    //     // console.log(obj)
    //     const res = await this.$http.get('/Lotorder/getLotOne/', obj)
    //     this.items = res.data
    //     console.log(this.items)
    //   } catch (error) {
    //     console.log(error)
    //   }
    // },
    async handleFileSelect(event) {
      const selectedFiles = event.target.files
      const promises = Array.from(selectedFiles).map(file => new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          // eslint-disable-next-line no-param-reassign
          file.preview = reader.result
          resolve(reader.result)
        }
        reader.onerror = error => {
          reject(error)
        }
        reader.readAsDataURL(file)
      }))
      Promise.all(promises)
        .then(base64Images => {
          this.selectedFiles = Array.from(selectedFiles)
          this.files = base64Images
        })
        .catch(error => {
          console.error('Error reading files:', error)
        })
    },
    // Submit() {
    //   const obj = {
    //     number_lot: this.fullname,
    //     name_lot: this.name,
    //     transportType: this.transportType,
    //     remark: this.remark,
    //     discount: this.discount,
    //     amount_out: this.amountExport,
    //     lot_file: this.file,
    //     lot_img: this.selectedFiles,
    //   }
    //   console.log(obj)
    //   this.$http.post('/Lotorder/update_lot', obj)

    // this.Success(this.$t('key-260'))

    // .then( => {
    //   this.Success(this.$t('key-260'))
    // })
    // .catch(error => console.log(error))
    // },
    saveParcel() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          const Obj = {
            fullname: this.name,
            tel: this.phone,
            address: this.address,
            province: this.province,
            district: this.district,
            subdistrict: this.subdistrict,
            postalcode: this.postalcode,
          }
          this.Address.push(Obj)
          this.$bvModal.hide('add-addr')
        }
      })
    },

    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
